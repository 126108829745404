/* eslint-disable react/prop-types */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import React, { useEffect, useReducer } from "react";
import IdleTimer from "react-idle-timer";
import Routes from "./routes";
import * as ACTIONS from "./store/actions/actions";
import { htmlActions } from "./store/redux/redux-actions/redux_actions";

import * as AccountsReducer from "./store/reducers/accounts_reducer.js";
import * as MenuReducer from "./store/reducers/menu_reducer.js";
import * as AuthReducer from "./store/reducers/auth_reducer";
import * as CallbacksReducer from "./store/reducers/callbacks_reducer.js";
import * as CustomerReducer from "./store/reducers/customer_reducer.js";
import * as DrawerReducer from "./store/reducers/drawer_reducer";
import * as FormReducer from "./store/reducers/form_reducer";
import * as OfferReducer from "./store/reducers/offer_reducer.js";
import * as Reducer1 from "./store/reducers/plain_reducer";
import * as SelectedAccountsReducer from "./store/reducers/selectedAccounts_reducer.js";
import * as SiteReducer from "./store/reducers/site_reducer.js";
import * as TimeOutReducer from "./store/reducers/timeout_reducer.js";
import { useDispatch, useSelector } from "react-redux";
import Timer from "react-compound-timer";
import { useInterval } from "react-interval-hook";
import Auth from "./utils/auth";
import history from "./utils/history";
import Context from "./utils/context";
import Fab from "./components/Fab/Fab";

import Countdown from "react-countdown";
import IntercomComponent from "./components/Header/IntercomComponent.js";
import { set } from "date-fns/fp";
import LinearProgress from "@material-ui/core/LinearProgress";

const auth = new Auth();

const ContextState = () => {
  const [IdleOpen, setIdleOpen] = React.useState(false);

  const [enableTimer, setEnableTimer] = React.useState(false);
  const dispatch = useDispatch();

  const [InfoOpen, setInfoOpen] = React.useState(false);
  const [FabOpen, setFabOpen] = React.useState(false);
  const openCamera = useSelector((state) => state.CameraReducer.open);

  const [InfoTitle, setInfoTitle] = React.useState(false);
  const [InfoText, setInfoText] = React.useState(false);
  const [InforedirectUrl, setInforedirectUrl] = React.useState("");
  const loading = useSelector((state) => state.ApiCallsReducer.loading);
  const sessionTimeout = useSelector(
    (state) => state.TimeOutReducer.sessionTimeout
  );
  const overallTimeout = useSelector(
    (state) => state.TimeOutReducer.overallTimeout
  );
  const startOverallTimeout = useSelector(
    (state) => state.TimeOutReducer.startOverallTimeout
  );
  const TokenTimer = () => {
    if (tokenInterval != "") {
      clearInterval(tokenInterval);
    }
    let tokenTimeout = sessionStorage.getItem("expiresInSeconds") * 1000;

    var interval = setInterval(() => {
      auth.checkToken();

      dispatch(ACTIONS.set_startoveralltimeout(true));
    }, tokenTimeout - sessionTimeout);
    setTokenInterval(interval);
  };
  const [tokenInterval, setTokenInterval] = React.useState("");
  useEffect(() => {
    // Function to check the timer status
    const checkTimer = () => {
      const expiresAt = sessionStorage.getItem("expiresAt");
      if (!expiresAt) {
        console.log("No active timer.");
        return;
      }

      const currentTime = Date.now();
      const timerTime = new Date(expiresAt);
      if (currentTime > timerTime) {
        auth.logout();
      } else {
        auth.RefreshToken();
      }
    };

    // Add event listener for visibility change
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        debugger;
        checkTimer();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  React.useEffect(() => {
    if (auth.isAuthenticatedUser()) {
      if (openCamera) {
        setFabOpen(false);
      } else {
        if (!FabOpen) {
          setFabOpen(true);
        }
      }
      setTimeout(() => {
        dispatch(htmlActions.setBurgerAnimation(false));
      }, 3000);
    } else {
      setFabOpen(false);
    }
  }, [openCamera, auth.isAuthenticatedUser()]);
  React.useEffect(() => {
    if (startOverallTimeout) {
      clearInterval(tokenInterval);
      TokenTimer();
      dispatch(ACTIONS.set_startoveralltimeout(false));
    }
    return () => {
      clearInterval(tokenInterval);
    };
  }, [startOverallTimeout]);
  const [planText, setPlanText] = React.useState("");

  const [drawerReducer, dispatchDrawer] = useReducer(
    DrawerReducer.DrawerReducer,
    DrawerReducer.initialState
  );

  const [drawerReducerOne, dispatchDrawerOne] = useReducer(
    DrawerReducer.DrawerReducer,
    DrawerReducer.initialState
  );
  const [drawerReducerTwo, dispatchDrawerTwo] = useReducer(
    DrawerReducer.DrawerReducer,
    DrawerReducer.initialState
  );
  const [drawerReducerThree, dispatchDrawerThree] = useReducer(
    DrawerReducer.DrawerReducer,
    DrawerReducer.initialState
  );

  const handleDispatchDrawer = (offer) => {
    dispatchDrawer(ACTIONS.set_Drawer(offer));
  };
  const handleDispatchDrawerOne = (offer) => {
    dispatchDrawerOne(ACTIONS.set_Drawer(offer));
  };
  const handleDispatchDrawerTwo = (offer) => {
    dispatchDrawerTwo(ACTIONS.set_Drawer(offer));
  };
  const handleDispatchDrawerThree = (offer) => {
    dispatchDrawerThree(ACTIONS.set_Drawer(offer));
  };

  const [offerReducer, dispatchOfferReducer] = useReducer(
    OfferReducer.OfferReducer,
    OfferReducer.initialState
  );

  const [menuReducer, dispatchMenuReducer] = useReducer(
    MenuReducer.MenuReducer,
    MenuReducer.initialState
  );

  const handleDispatchOffer = (offer) => {
    dispatchOfferReducer(ACTIONS.set_Offer(offer));
  };

  const [customerReducer, dispatchCustomerReducer] = useReducer(
    CustomerReducer.CustomerReducer,
    CustomerReducer.initialState
  );

  const handleDispatchCustomer = (customer) => {
    dispatchCustomerReducer(ACTIONS.set_Customer(customer));
  };

  const [accountsReducer, dispatchAccountsReducer] = useReducer(
    AccountsReducer.AccountsReducer,
    AccountsReducer.initialState
  );

  const [selectAccountReducer, dispatchsSlectAccountReducer] = useReducer(
    SelectedAccountsReducer.SelectedAccountsReducer,
    SelectedAccountsReducer.initialState
  );
  const handleDispatchSelectAccountReducer = (account) => {
    dispatchsSlectAccountReducer(ACTIONS.set_selectedAccount(account));
  };

  const handleDispatchSite = (site) => {
    dispatchSiteReducer(ACTIONS.set_Site(site));
  };

  const [siteReducer, dispatchSiteReducer] = useReducer(
    SiteReducer.SiteReducer,
    SiteReducer.initialState
  );

  const handleDispatchCallbacks = (callbacks) => {
    dispatchCallbacksReducer(ACTIONS.set_Callbacks(callbacks));
  };

  const [callbacksReducer, dispatchCallbacksReducer] = useReducer(
    CallbacksReducer.CallbacksReducer,
    CallbacksReducer.initialState
  );

  const [numAccounts, setNumAccounts] = React.useState(1);

  const handleDispatchAccounts = (accounts) => {
    setNumAccounts(accounts.length);
    dispatchAccountsReducer(ACTIONS.set_Accounts(accounts));
  };

  const handleMenuReducer = (menu) => {
    dispatchMenuReducer(ACTIONS.set_menu(menu));
  };

  const [stateReducer1, dispatchReducer1] = useReducer(
    Reducer1.Reducer1,
    Reducer1.initialState
  );

  const handleDispatchTrue = () => {
    //    dispatchReducer1(type: "SUCCESS")
    //    dispatchReducer1(ACTIONS.SUCCESS)
    dispatchReducer1(ACTIONS.success());
  };

  const handleDispatchFalse = () => {
    //     dispatchReducer1(type: "FAILURE")
    //    dispatchReducer1(ACTIONS.FAILURE)
    dispatchReducer1(ACTIONS.failure());
  };

  /*
      Auth Reducer
    */
  const [stateAuthReducer, dispatchAuthReducer] = useReducer(
    AuthReducer.AuthReducer,
    AuthReducer.initialState
  );

  const handleLogin = () => {
    dispatchAuthReducer(ACTIONS.login_success());
  };

  const handleLogout = () => {
    dispatchAuthReducer(ACTIONS.login_failure());
  };

  const handleAddProfile = (profile) => {
    dispatchAuthReducer(ACTIONS.add_profile(profile));
  };

  const handleRemoveProfile = () => {
    dispatchAuthReducer(ACTIONS.remove_profile());
  };

  /*
      Form Reducer
    */

  const [stateFormReducer, dispatchFormReducer] = useReducer(
    FormReducer.FormReducer,
    FormReducer.initialState
  );

  const handleFormChange = (event) => {
    dispatchFormReducer(ACTIONS.user_input_change(event.target.value));
  };

  const OpenInfoDialog = (title, text, redirectUrl) => {
    setInfoOpen(true);
    setInfoTitle(title);
    setInfoText(text);
    if (redirectUrl != null) {
      setInforedirectUrl(redirectUrl);
    }
    //auth.logout();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    event.persist();
    dispatchFormReducer(
      ACTIONS.user_input_submit(event.target.useContext.value)
    );
  };

  //Handle authentication from callback
  const handleAuthentication = (props) => {
    if (props.location.hash) {
      auth.handleAuth();
    }
  };

  const onAction = (e) => {
    if (auth.isAuthenticatedUser()) {
      auth.checkToken();
    } else {
      // setFabOpen(false);
    }
  };

  const onActive = (e) => {};

  const onIdle = (e) => {
    if (auth.isAuthenticatedUser()) {
      handleIdleOpen(true);
    }
  };

  const handleIdleOpen = () => {
    setInfoOpen(false);

    setIdleOpen(true);
  };

  const handleClose = (value) => {
    if (value === 1) {
      auth.checkToken();
    } else {
      auth.logout();
    }
    setIdleOpen(false);
  };
  const handleInfoClose = (value, redirect) => {
    setInfoOpen(false);
    if (redirect != "") {
      history.push(redirect);
    }
  };

  const handleIntercomKey = (value, visible) => {
    setIntercomKey(value);
    setIntercomVisible(visible);
  };

  const [selectedValue, setSelectedValue] = React.useState(0);

  const [IntercomKey, setIntercomKey] = React.useState("");
  const [IntercomVisible, setIntercomVisible] = React.useState(false);

  // const handleDispatchSessionTimeout = (timeout) => {
  //   dispatchSessionTimeout(ACTIONS.set_sessiontimeout(timeout));
  // };
  // const handleDispatchOverallTimeout = (timeout) => {
  //   dispatchOverallTimeout(ACTIONS.set_overalltimeout(timeout));
  // };

  // const [sessionTimeout, dispatchSessionTimeout] = useReducer(
  //   TimeOutReducer.TimeOutReducer,
  //   TimeOutReducer.initialState.sessionTimeout
  // );
  // const [overallTimeout, dispatchOverallTimeout] = useReducer(
  //   TimeOutReducer.TimeOutReducer,
  //   TimeOutReducer.initialState.overallTimeout
  // );

  return (
    <div>
      {loading == true ? <LinearProgress /> : null}

      <Context.Provider
        value={{
          //Reducer1
          stateProp1: stateReducer1.stateprop1,
          stateProp2: stateReducer1.stateprop2,
          dispatchContextTrue: () => handleDispatchTrue(),
          dispatchContextFalse: () => handleDispatchFalse(),

          stateDrawer: drawerReducer.drawer,
          dispatchDrawer: (state) => handleDispatchDrawer(state),
          dispatchDrawerOne: (state) => handleDispatchDrawerOne(state),
          dispatchDrawerTwo: (state) => handleDispatchDrawerTwo(state),
          dispatchDrawerThree: (state) => handleDispatchDrawerThree(state),

          dispatchIntercomKey: (key, visible) =>
            handleIntercomKey(key, visible),

          stateOne: drawerReducerOne.drawer,
          stateTwo: drawerReducerTwo.drawer,
          stateThree: drawerReducerThree.drawer,

          statePlanText: planText,
          setstatePlanText: (value) => setPlanText(value),

          stateCallbacks: callbacksReducer.callbacks,
          dispatchCallbacksReducer: (callbacks) =>
            handleDispatchCallbacks(callbacks),

          stateInfoDialog: (title, value, redirectUrl) =>
            OpenInfoDialog(title, value, redirectUrl),

          stateSelectedAccount: selectAccountReducer.account,
          dispatchSelectAccountReducer: (account) =>
            handleDispatchSelectAccountReducer(account),

          stateCustomer: customerReducer.customer,
          dispatchCustomerReducer: (customer) =>
            handleDispatchCustomer(customer),

          dispatchMenuReducer: (menu) => handleMenuReducer(menu),

          stateMenu: menuReducer.menu,

          stateAccounts: accountsReducer.accounts,
          stateNumAcctions: numAccounts,
          dispatchAccountsReducer: (accounts) =>
            handleDispatchAccounts(accounts),

          stateSite: siteReducer.site,
          dispatchSiteReducer: (site) => handleDispatchSite(site),

          // stateSessionTimeout: sessionTimeout.sessionTimeout,
          // dispatchSessionTimeoutReducer: (time) =>
          //   handleDispatchSessionTimeout(time),

          // stateOverallTimeout: overallTimeout.overallTimeout,
          // dispatchOverallTimeoutReducer: (time) =>
          //   handleDispatchOverallTimeout(time),

          stateOffer: offerReducer.offer,
          dispatchOfferReducer: (offer) => handleDispatchOffer(offer),
          //Form Reducer
          useContextChangeState: stateFormReducer.user_textChange,
          useContextSubmitState: stateFormReducer.user_textSubmit,
          useContextSubmit: (event) => handleFormSubmit(event),
          useContextChange: (event) => handleFormChange(event),

          //Auth Reducer
          authState: stateAuthReducer.is_authenticated,
          profileState: stateAuthReducer.profile,
          handleUserLogin: () => handleLogin(),
          handleUserLogout: () => handleLogout(),
          handleUserAddProfile: (profile) => handleAddProfile(profile),
          handleUserRemoveProfile: () => handleRemoveProfile(),

          //Handle auth
          handleAuth: (props) => handleAuthentication(props),
          authObj: auth,
        }}
      >
        <Routes />

        <IntercomComponent
          loggedID={auth.isAuthenticatedUser()}
          id={sessionStorage.getItem("user")}
          name={sessionStorage.getItem("user")}
          chatvisible={IntercomVisible}
          authKey={IntercomKey}
        />

        <IdleDialog
          open={IdleOpen}
          timeoutvalue={sessionTimeout}
          selectedValue={selectedValue}
          onClose={(e) => handleClose(e)}
        />
        <InfoDialog
          open={InfoOpen}
          title={InfoTitle}
          text={InfoText}
          redirectUrl={InforedirectUrl}
          onClose={(e, redirect) => handleInfoClose(e, redirect)}
        />

        <IdleTimer
          element={document}
          onActive={onActive}
          onIdle={onIdle}
          onAction={onAction}
          debounce={250}
          timeout={sessionTimeout}
        />
      </Context.Provider>
    </div>
  );
};

export default ContextState;

const useStyles = makeStyles({
  button: {
    textAlign: "center",
  },
});

if (window.performance) {
  if (performance.navigation.type == 1) {
    auth.logout();
  }
}

function IdleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [left, setLeft] = React.useState(0);
  const { value, setValue } = React.useState(0);
  const calculateLeft = (from) => {
    return from - left;
  };
  const [autoLogout, setAutoLogout] = React.useState(60);
  const [display, setDisplay] = React.useState(60);
  const handleClose = (e) => {
    onClose(e);
    setAutoLogout(0);
  };
  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open} disableBackdropClick>
      <DialogTitle id="simple-dialog-title">Session Expiry</DialogTitle>
      <DialogContent>
        You will be logged out due to inactivity on this session. If you wish
        keep the session open, click Yes immediately. Do you wish to continue?
        (Yes or No)
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(0)} color="primary">
          No (
          <Countdown
            date={Date.now() + props.timeoutvalue}
            intervalDelay={0}
            precision={1}
            onComplete={() => handleClose(0)}
            renderer={(props) => (
              <div>
                {props.minutes}:{props.seconds}
              </div>
            )}
          />{" "}
          )
        </Button>
        <Button onClick={() => handleClose(1)} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function InfoDialog(props) {
  const classes = useStyles();
  const { onClose, open, title, text, redirectUrl } = props;
  const { value, setValue } = React.useState(0);
  const handleClose = (e) => {
    onClose(e, redirectUrl);
  };

  const handleListItemClick = (value) => {
    onClose(value, redirectUrl);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(1)} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
